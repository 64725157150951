<template>
  <ServiceComboFormPart :service-selections="serviceSelections" v-on="$listeners" />
</template>

<script>
import {
  insulationBottomConsumerNL,
  insulationBottomCorporateNL,
  insulationCavityWallConsumerNL,
  insulationCavityWallCorporateNL,
  insulationCrawlspaceConsumerNL,
  insulationCrawlspaceCorporateNL,
  insulationFloorConsumerNL,
  insulationFloorCorporateNL,
  insulationRoofConsumerNL,
  insulationRoofCorporateNL
} from 'chimera/insulation/service'
import ServiceComboFormPart from 'chimera/all/themes/blueflow/components/form/part/service/ServiceComboFormPart'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceComboFormPart
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      serviceSelections: [
        new Selectable(
          'Kruipruimteisolatie',
          'Kruipruimteisolatie',
          'Kruipruimteisolatie',
          {
            consumer: insulationCrawlspaceConsumerNL,
            corporate: insulationCrawlspaceCorporateNL
          }
        ),
        new Selectable(
          'Dakisolatie',
          'Dakisolatie',
          'Dakisolatie',
          {
            consumer: insulationRoofConsumerNL,
            corporate: insulationRoofCorporateNL
          }
        ),
        new Selectable(
          'Vloerisolatie',
          'Vloerisolatie',
          'Vloerisolatie',
          {
            consumer: insulationFloorConsumerNL,
            corporate: insulationFloorCorporateNL
          }
        ),
        new Selectable(
          'Spouwmuurisolatie',
          'Spouwmuurisolatie',
          'Spouwmuurisolatie',
          {
            consumer: insulationCavityWallConsumerNL,
            corporate: insulationCavityWallCorporateNL
          }
        ),
        new Selectable(
          'Bodemisolatie',
          'Bodemisolatie',
          'Bodemisolatie',
          {
            consumer: insulationBottomConsumerNL,
            corporate: insulationBottomCorporateNL
          }
        )
      ]
    }
  }
}
</script>
